.gyik{
  .gyik-kerdes-valasz{
    margin-bottom: 15px;
  }

  hr{
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .gyik-kerdes{
    color: $text-color;
    font-weight: bold;
    font-size: 18px;


    &:hover,&:focus{
      color: $voros;
    }
  }
  .gyik-valasz{
    text-align:justify;
  }
}
