#figyelmeztetes-modal {
  background: rgba(255, 255, 255, 0.8);
  opacity: 1;

  .modal-dialog {
    .modal-content {
      background: $piros;
      border-radius: 0;
      border-bottom-left-radius: 24px;
      border-top-right-radius: 24px;
      border: none;
      color: white;
      padding: 30px 50px 40px 35px;
      box-shadow: none;
      position: relative;
      font-size: 18px;

      @media (max-width: $screen-sm-max) {
        font-size: 14px;
      }

      .close {
        position: absolute;
        top: 11px;
        right: 13px;
        color: white;
        opacity: 1;

        &:hover, &:focus {
          opacity: 0.8;
        }
      }
    }
  }
}

.modal-backdrop {
  display: none;
}

.tartalom {
  margin-top: 40px;
  .row {
    .bal-oldal {
      border-bottom-left-radius: 24px;
      overflow: hidden;
      padding: 25px 10px 30px 0;
      margin-bottom: 30px;
//      min-height: 300px;

      .almenu {
	font-family: fs_rufusregular,serif;
        margin-bottom: 0;
        list-style: none;
        padding-left: 22px;

        > li {
          margin: 7px 0;
          @media (max-width: $screen-sm-max){
            margin: 9px 0;
          }
          a {
            font-size: 18px;
            font-weight: bold;
            color: #666666;
            line-height: 1;

            &:hover, &:focus {
              color: $voros;
            }

            &.active {
              color: $voros;
            }
          }
        }

        .harmadikszint-menu {
          margin: 5px 0;
          @media (max-width: $screen-sm-max){
            margin: 7px 0;
          }
          > a {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .jobb-oldal {
      .kereses, .fontos-informaciok, .idojaras {
        border-bottom-left-radius: 24px;
        border-top-right-radius: 24px;
        overflow: hidden;
        margin-bottom: 30px;

        .felso-sav {
          min-height: 36px;
          margin-bottom: 5px;
          position: relative;

          p {
            /*position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);*/
            color: white;
            font-size: 20px;
            font-weight: bold;
            padding-top: 5px;
            margin-bottom: 0;
          }

          > img {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            z-index: 20;
          }
        }

        .also-sav {
          background: #f2f2f2;
        }
      }

      .kereses {
        .also-sav {
          padding: 32px 20px 15px;
          position: relative;

          input {
            background: transparent;
            width: 100%;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #d0d0d0;
          }

          #kereses_gomb {
            position: absolute;
            bottom: 20px;
            right: 22px;
          }
        }
      }

      .fontos-informaciok {
        .also-sav {
          padding: 42px 15px 15px;
          background: #f2f2f2 url('/images/bg_info.jpg') 0 100% no-repeat;
          font-size: 14px;

          .margin-top {
            margin-top: 10px;
          }

          .telefon {
            font-size: 18px;
            font-weight: bold;
          }

          a {
            color: $voros;
            font-size: 18px;
            margin-bottom: 15px;

            &:hover, &:focus {
              color: $piros;
            }
          }

          hr {
            margin: 0;
            padding: 10px 0;
            margin-top: 10px;
            border-color: #d0d0d0;
          }
        }
      }

      .idojaras {
        .also-sav {
          padding: 20px 15px 15px;
          background: #f2f2f2 url('/images/bg_ido.jpg') 0 100% no-repeat;

          .telepules-nev {
            text-align: center;
            font-size: 25px;
            color: #b2b2b2;
            margin-bottom: 5px;
          }
          .ikonok {
            display: flex;
            margin-bottom: 10px;
            .idojaras-ikon, .homerseklet {
              display: inline-block;
              width: 50%;
              font-size: 17px;
            }

            .idojaras-ikon {
              i {
                font-size: 40px;
                color: $narancs;
              }
            }
          }

          hr {
            margin: 0;
            padding: 10px 0;
            border-color: #d0d0d0;
          }

          .nevnap {
            font-size: 17px;

            img {
              margin-bottom: 5px;
            }

            .datum {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
