$fonts-dir: "../../fonts/";

@font-face {
	font-family: 'fs_rufusregular';
	src: url('#{$fonts-dir}fs_rufus-regular-webfont.woff2') format('woff2'),
	url('#{$fonts-dir}fs_rufus-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'fs_rufusbold';
	src: url('#{$fonts-dir}fs_rufus-bold-webfont.woff2') format('woff2'),
	url('#{$fonts-dir}fs_rufus-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'thesans_veoliaw4_semilight';
	src: url('#{$fonts-dir}thesansveolia-w4selig-webfont.woff2') format('woff2'),
	url('#{$fonts-dir}thesansveolia-w4selig-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'thesans_veoliaw5_plain';
	src: url('#{$fonts-dir}thesansveolia-w5pla-webfont.woff2') format('woff2'),
	url('#{$fonts-dir}thesansveolia-w5pla-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'thesans_veoliaw7_bold';
	src: url('#{$fonts-dir}thesansveolia-w7bld-webfont.woff2') format('woff2'),
	url('#{$fonts-dir}thesansveolia-w7bld-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}