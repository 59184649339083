.hirek {
  //figyelmeztetés rész
  .figyelmeztetes {
    background: #f68e76;
    border-radius: 0;
    border-bottom-left-radius: 24px;
    border-top-right-radius: 24px;
    border: none;
    color: white;
    padding: 30px 50px 40px 35px;
    box-shadow: none;
    position: relative;
    font-size: 16px;
  }

  hr{
    border-color: #d0d0d0;
  }

  .hir-cim {
    font-size: 20px;
    margin: 0 0 18px ;
    color: $text-color;
    font-family: thesans_veoliaw7_bold;

    &:hover, &:focus {
      color: $voros;
    }
  }

  .hir-link {
    &:hover, &:focus {
      .hir-cim {
        color: $voros;
      }
    }
  }

  .lead {
    color: $text-color;
    font-size: 16px;
  }

  .datum {
    padding-left: 20px;
    margin-bottom: 24px;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
}

.hir{
  padding-bottom: 40px;
  .oldal-cim{
    margin-bottom: 15px;
  }

  .lead{
    color: #666666;
    font-family: thesans_veoliaw7_bold;
  }
}