@import "_fonts";
@import "_bootstrap.scss";
@import "_header.scss";
@import "navbar-toggle-animation";
@import "_layout.scss";
@import "_footer.scss";
@import "_szoveges.scss";
@import "_fooldal.scss";
@import "_kapcsolat.scss";
@import "_gyik.scss";
@import "_dokumentumok.scss";
@import "_hir.scss";
@import "_kerdoiv.scss";
@import "_akadalymentes.scss";

@import "mobil/_header.scss";
@import "mobil/_footer.scss";



.container-fluid {
  padding: 30px 30px 0px;
}

//színek generálása a háttérhez
@each $szin, $valtozo in (
      //darkblue: $darkblue,
        sarga: $sarga,
        piros: $piros,
        cian:$cian,
        kek: $kek,
        narancs: $narancs,
        lila: $lila,
        zold: $zold,
        sotetzold: $sotetzold,
        vilagoskek: $vilagoskek,
        rozsaszin: $rozsaszin,
        sotetkek: $sotetkek,
        libazold: $libazold,
        szurke: $szurke,
        vilagos: $vilagos) {
  .bg-#{$szin} {
    background: $valtozo;
  }
}

a {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.oldal-cim {
  font-size: 30px;
  color: $voros;
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: bold;
}

.heading-font {
  font-family: $headings-font-family;

  &.bold {
    font-family: fs_rufusbold, serif;
  }
}

.btn {
  min-height: 26px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 0;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 24px;
  border-color: transparent;
  color: white;

  &:hover, &:focus {
    color: white;
  }
}

.btn.btn-sm {
  min-height: 25px;
  font-size: 12px;
  padding: 3px 11px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
}

.btn-nyil-jobbra {
  padding-left: 38px;
  background-image: url('/images/jobbra-nyil.png');
  background-position: 15px center;
  background-repeat: no-repeat;

  &:hover, &:focus {
    background-position: 18px center;
  }
}

.btn-nyil-balra {
  padding-left: 38px;
  background-image: url('/images/balra-nyil.png');
  background-position: 15px center;
  background-repeat: no-repeat;

  &:hover, &:focus {
    background-position: 18px center;
  }
}

.btn-nyil-jobbra.btn-sm, .btn-nyil-balra.btn-sm {
  padding-left: 28px;
  background-position: 10px center;

  &:hover, &:focus {
    background-position: 13px center;
  }
}

.color-text {
  color: $text-color;
}

.g-recaptcha {
  padding-right: 15px;
}

strong {
  font-family: thesans_veoliaw7_bold, sans-serif;
}

#kereses_gomb {
  background: url(/images/nagyito.png);
  display: block;
  float: right;
  text-indent: -9999px;
  width: 13px;
  height: 13px;
  border: none;
}

.akadaly img {
  display: block !important;
  visibility: visible !important;
}
