.szoveg {
  margin-bottom: 30px;
  text-align: justify;
  a {
    color: $voros;

    &:hover,&:focus{
      color: darken($voros,10%);
    }
  }

  a.btn{
    color: white;
  }

  ol{
    list-style-position: outside;
    padding-left: 15px;
  }

  ul{
    list-style-position: outside;
    padding-left: 17px;
  }

  img {
    max-width: 100%;
    @include img-responsive();
  }

  figcaption {
    color: $voros;
    font-size: 16px;
  }

  table {
    margin-bottom: 25px;
    width: 100%;
    border: 0;

    tr {
        background-color: #e3e3e3;
        border: 1px solid  lighten($szurke,20%);
        color: darken($text-color, 15%);
        > th {
          border: 1px solid  lighten($szurke,20%);
          vertical-align: middle;
          padding: 5px 10px;
          color: $text-color;
          background-color: #e3e3e3;
        }
    }

    tbody {
      > tr {
        border: 1px solid lighten($szurke,40%);
        > td {
          border: 1px solid lighten($szurke,10%);
          padding: 13px 10px;
          vertical-align: middle;
          color: $text-color;
        }
      }
      > tr:nth-child(odd) {
        background-color: #fff;
      }

      > tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
}
