$akadalymentes-betu: #eeee33;

html.akadalymentes {
  * {
    background-color: #000000 !important;
    color: $akadalymentes-betu !important;
    background-image: none !important;
  }
  img{
    visibility:hidden;
  }
  .szoveg img{
    display:none;
  }
  body {
    background-image: none !important;
  }

  .icon-bar {
    background: $akadalymentes-betu !important;
  }

  hr,
  .table,
  .table th,
  .table td,
  .table-responsive,
  .btn-default,
  .form-control,
  .pagination a[rel],
  .navbar-toggle {
    border-color: $akadalymentes-betu !important;
  }

  .lang-select .active {
    background: $akadalymentes-betu !important;
    color: #000 !important;
  }

  .btn{
    border-color: $akadalymentes-betu !important;

    &:hover,&:focus{
      background-color: $akadalymentes-betu !important;
      color: #000 !important;
    }
  }

  .pagination .active span {
    border-color: $akadalymentes-betu !important;
    background: $akadalymentes-betu !important;
    color: #000 !important;
  }
}
