header {
  @media (max-width: $screen-sm-max) {
    &.container-fluid {
      padding: 30px 15px 0;
    }
  }

  .felso-resz {
    position: relative;
    .veolia-logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      img {
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
          max-height: 55px;
        }
        @media (min-width: 1251px) and (max-width: 1350px) {
          max-height: 65px;
        }
      }

      @media (max-width: $screen-sm-max) {
        max-width: 134px;
        right: auto;

        img {
          max-width: 100%;

        }
      }
    }

    .akadaly {
      position: absolute;
      bottom: -40px;
      right: 0;
      img {
        max-height: 35px;
      }
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -48%);
      color: white;
      font-size: 40px;
      font-weight: bold;
      margin: 0;
      text-shadow: 2px 2px 2px black;
      width: 100%;
      text-align: center;
      padding-top: 18px;

      @media (max-width: $screen-xs-max) {
        font-size: 24px;
      }
    }

    .felso-kep {
      border-bottom-left-radius: 24px;
      border-top-right-radius: 24px;
    }

  }

  .mobil-menu {
    @media (max-width: $screen-sm-max) {
      padding: 0;
      margin: 0;
      max-width: 100%;

      .menu-szoveg {
        color: white;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
      }
      .navbar-header {
        position: relative;
        margin: 40px 0 10px;
        background: $cian;
        border-top-right-radius: 24px;
        overflow: hidden;

        .navbar-toggle {
          margin: 0 5px 0 0;

          > span {
            background-color: white;
          }
        }
      }
    }
  }

  .container {
    .collapse, .collapsing {
      padding: 0;

      .navbar-nav {
        width: 100%;
        display: flex;
        @media (min-width: $screen-sm-min) {
          justify-content: space-between;
          align-items: stretch;
        }

        .felso-menu {
          font-size: 20px;
          color: #808080;
          padding-top: 20px;
          padding-bottom: 20px;
          @media (max-width: $screen-xs-max) {
            padding: 10px 10px 10px 0;
            display: inline-block;
          }

          &:hover, &:focus {
            background: transparent;
            color: $voros;
          }

          &.active {
            color: $voros;
          }
        }
      }
    }
  }
}
