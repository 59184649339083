.kapcsolat-oldal {
  .kapcsolat-cim{
    font-size: 22px;
    margin-bottom: 30px;
    font-family: thesans_veoliaw4_semilight;
    font-weight: bold;
  }
  .kapcsolat-form {
    input,textarea {
      background: #f2f2f2;
      border: none;

      &:focus{
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(red($voros), green($voros), blue($voros), 0.6);
      }
    }

    label {
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
