.dokumentumok {

  .dokumentum-lista {
    list-style: none;
    padding-left: 0;

    li{
      margin: 10px 0;
      position: relative;
      padding-left: 30px;
      a{
        color: $text-color;

        &:hover,&:focus{
          color: $voros;
        }

        img{
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0,-50%);
        }
      }
    }
  }
}