.fooldal {

  //település boxok
  .telepulesek {
    padding: 50px 0 82px;
    > .container {
      .row {
        > .telepules {
          padding: 0 11px;
          margin-bottom: 20px;

          > img {
            border-top-right-radius: 24px;
            margin-bottom: 80px;
          }

          > .szoveg {
            position: absolute;
            bottom: 0;
            left: 11px;
            right: 11px;
            border-top: 5px solid white;
            border-bottom-left-radius: 24px;
            height: 80px;
            transition: height 0.5s;

            > img {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0);
            }

            > .cim {
              color: white;
              font-size: 22px;
              font-weight: bold;
              position: absolute;
              top: 22px;
              left: 0;
              right: 0;
              margin-left: 0;
              margin-right: 0;
              line-height: 1;
            }
            > .alcim {
              color: white;
              font-size: 13px;
              position: absolute;
              top: 45px;
              left: 0;
              right: 0;
              margin-left: 0;
              margin-right: 0;
              line-height: 1;
            }

            > .hover-felso {
              display: none;

              .bal-iv {
                position: absolute;
                bottom: -6px;
                left: -15px;
                svg {
                  height: 15px;
                  opacity: 0.2;
                }
              }

              .jobb-iv {
                position: absolute;
                bottom: -6px;
                right: -16px;
                svg {
                  height: 15px;
                  opacity: 0.2;
                }
              }
            }

            > .hover-also {
              display: none;

              > p {
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                font-size: 12px;
                color: white;
                font-weight: bold;

                > img {
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  transform: translate(0, -50%);
                }
              }
            }
          }

          &:hover {
            > .szoveg {
              height: 115px;

              > .hover-felso {
                display: block;
                position: absolute;
                bottom: 22px;
                left: 28px;
                right: 28px;
                height: 15px;
                padding: 0 15px;
                background: rgba(0, 0, 0, 0.2);
              }

              > .hover-also {
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                height: 22px;
                background: rgba(0, 0, 0, 0.2);
                border-bottom-left-radius: 24px;
              }
            }
          }
        }
      }
    }
  }

  //video
  .video {
    position: relative;
    background: url('/images/video.jpg');
    padding: 110px 0 65px;

    .video-cim{
      text-align: center;
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 25px;
      color: $text-color;
      z-index: 110;
    }

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(227, 227, 227, 0.85);
      z-index: 100;
    }

    .row {
      margin: 0;
      z-index: 110;
      .video-layer{
        z-index: 110;
      }
    }
    .fooldal-video-lejatszo-kep {
      border-top-right-radius: 24px;
      border-bottom-left-radius: 24px;
      img{
        border-top-right-radius: 24px;
        border-bottom-left-radius: 24px;
      }
      .lejatszo-gomb {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    #bgvid {
      border-top-right-radius: 24px;
      border-bottom-left-radius: 24px;

      visibility: hidden;

      &.lathato {
        visibility: visible;
      }

    }

    video::-webkit-media-controls-panel{
      border-bottom-left-radius: 24px;
    }
  }
}
