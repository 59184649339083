@media (max-width: $screen-sm-max) {
  header {
    .container {
      .collapse, .collapsing {
        .navbar-nav {
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0;

          > .menu-elem {
            padding: 10px 0 10px 10px;
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

}