footer{
  min-height: 50px;
  background: #2c2c2c;
  .container{
    position: relative;
    min-height: 50px;
    font-size: 16px;

    ul{
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translate(0,-50%);
      li{
        color: white;
        @media (max-width: $screen-xs-max) {
          display: block;
        }
        a{
          color: #9d9d9c;
          text-decoration: none;

          &:hover{
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    .footer-link{
      position: absolute;
      right: 185px;
      top: 50%;
      transform: translate(0,-50%);

      color: #9d9d9c;
      text-decoration: none;

      &:hover{
        color: white;
        cursor: pointer;
      }
    }

    img{
      position: absolute;
      right: 45px;
      top: 50%;
      transform: translate(0,-50%);
    }

    .akadaly{
      img{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0,-50%);
        max-height: 29px;
      }
    }
  }
}