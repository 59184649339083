footer {
  @media (max-width: $screen-xs-max) {
    .lablec {
      display: block;
      width: 100%;

      ul, .footer-link {

        position: relative;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        transform: none;
      }
    }
  }
}
